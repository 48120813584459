<template>
  <div>
    <div class="Map">
      <baidu-map
        class="map"
        :center="center"
        :zoom="zoom"
        @ready="handler"
        @click="getClickInfo"
        :scroll-wheel-zoom="true"
      >
        <div v-for="(item, i) in AllSiteList" :key="i">
          <bm-marker
            :position="{ lng: item.lng, lat: item.lat }"
            :icon="{
              url:
                item.status == '0'
                  ? 'https://qiniu1.xuruidea.com/27/20201225/green1608882423166.png'
                  : item.status == '1'
                  ? 'https://qiniu1.xuruidea.com/27/20210104/equRed1609748311341.png'
                  : 'https://qiniu1.xuruidea.com/23461611992132_.pic_hd.jpg',
              size: { width: 40, height: 60 }
            }"
            :zIndex="10"
            @mouseover="
              openInfoBox(
                item.lng,
                item.lat,
                item.deviceAddress,
                item.storageName,
                item.dataTime
              )
            "
          >
            <bm-label
              :content="item.storageName"
              :zIndex="9"
              :labelStyle="{
                border: '1px solid #0E7771',
                color: '#000000',
                fontSize: '17px',
                textIndent: '25px',
                paddingRight: '10px',
                borderRadius: '8px'
              }"
              :offset="{ width: 15, height: 18 }"
            />
          </bm-marker>
        </div>
      </baidu-map>
      <!-- <div id="containers"></div> -->
      <!-- <infoWindowComponent ref="infoWindowComponent"></infoWindowComponent> -->
      <!-- 搜索弹框 -->
      <div class="search flex">
        <el-input
          v-model="storageName"
          placeholder="请输入设备名称"
        /><el-button @click="searchList" type="primary">查询</el-button>
      </div>
      <!-- 设备弹框-->
      <div class="FacilityBack Card-b-c">
        <ul>
          <li>
            <span class="round font-s-14">在线设备</span>
            <div class="text font-s-18 font-w-b">{{ onlineCount }}</div>
            <span class="font-s-18">台</span>
          </li>
          <li>
            <span class="rounds font-s-14">位移报警</span>
            <div class="text font-s-18 font-w-b">{{ displacementCount }}</div>
            <span class="font-s-18">台</span>
          </li>
          <li>
            <span class="round-gray font-s-14">离线设备</span>
            <div class="text font-s-18 font-w-b">{{ offlineCount }}</div>
            <span class="font-s-18">台</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "../../components/Header";
// import infoWindowComponent from "./infowindow";
import { getStorageAll } from "../../api/FacilityBack";
// import Popup from "./components/popup";
export default {
  data() {
    return {
      infoBox: null,
      lastInfoBox: null,
      Address: "",
      infoBox: null,
      AllSiteList: [], //站点列表
      center: { lng: 114.424961, lat: 30.485224 },
      zoom: 10,
      time: 12,
      displacementCount: 0,
      offlineCount: 0,
      onlineCount: 0,
      storageName: "" //设备名称
    };
  },
  mounted() {
    this.getList();
    var that = this;
    window.onInfoBoxClick = function(lng, lat, deviceAddress, storageName) {
      that.$router.push({
        path: `/gps?deviceAddress=${deviceAddress}&lng=${lng}&lat=${lat}&storageName=${storageName}`
      });
    };
  },
  methods: {
    // 获取地图中的设备列表
    getList() {
      getStorageAll().then(res => {
        console.log("getList", res);
        this.AllSiteList = res.data.storageVos;
        this.center = res.data.storageVos[0];
        this.displacementCount = res.data.displacementCount;
        this.offlineCount = res.data.offlineCount;
        this.onlineCount = res.data.onlineCount;
      });
    },
    // 搜索的方法
    searchList() {
      this.AllSiteList = [];
      getStorageAll({
        storageName: this.storageName
      }).then(res => {
        console.log("searchList", res);
        this.AllSiteList = res.data.storageVos;
        this.center = res.data.storageVos[0];
        this.displacementCount = res.data.displacementCount;
        this.offlineCount = res.data.offlineCount;
        this.onlineCount = res.data.onlineCount;
      });
    },
    // 打开信息窗口
    openInfoBox(lng, lat, deviceAddress, storageName, dataTime) {
      this.analysisAddress([{ lng: lng, lat: lat }], res => {
        this.Address = JSON.stringify(res);
        this.Address = this.Address.replace(/\[|]/g, "").replace(
          /^\"|\"$/g,
          ""
        );
        let html = "";
        html += "<div class='Back_box'>";
        html += "<ul>";
        html += "<li>";
        html += `<div class='texts font-w-b '>${storageName}</div>`;
        html += `<div class='blue' onclick='onInfoBoxClick("${lng}","${lat}","${deviceAddress}","${storageName}")'><img src="https://qiniu1.xuruidea.com/27/20210122/3508109067881914031611285919386.png" />查看轨迹</div>`;
        html += "</li>";
        html += "<li>";
        html += `<span class='gray'>设备当前位置：</span><span class='font-w-b'>${this.Address}</span>`;
        html += "</li>";
        html += "<li>";
        html += `<span class='gray'> 定位上次更新时间： </span><span class='font-w-b'>${dataTime}</span>`;
        html += "</li>";
        html += "</ul>";
        html += "</div>";
        let opts = {
          boxStyle: {
            width: "380px",
            height: "100px",
            marginLeft: "190px"
            // marginTop: "42px"
          },

          closeIconMargin: "10px 180px 0 0",
          closeIconUrl: require("../../assets/images/FacilityBack/kongbai.png"),
          enableAutoPan: true,
          align: INFOBOX_AT_BOTTOM
        };
        this.infoBox = new BMapLib.InfoBox(this.map, html, opts);
        let point = new BMap.Point(lng, lat);
        let marker = new BMap.Marker(point);
        if (this.lastInfoBox) {
          //判断上一个窗体是否存在，若存在则执行close
          this.lastInfoBox.close();
        }
        this.lastInfoBox = this.infoBox;
        this.infoBox.open(marker);
      });
    },
    closeInfoBox() {
      if (this.infoBox != null) {
        this.infoBox.close();
      }
    },
    handler({ BMap, map }) {
      this.map = map;
    },
    analysisAddress(arr, callBack) {
      // 判断传入的arr是否为数组
      if (!Array.isArray(arr) || arr.length === 0) {
        // eslint-disable-next-line no-throw-literal
        throw "arr not a Array or arr not is null Array!";
      }

      // eslint-disable-next-line no-undef
      var Geo = new BMap.Geocoder();
      var adds = arr;
      var addressList = [];
      let i = 0;

      // 逐个逆向解析
      async function searchGeo() {
        // 传入的坐标必须是数字
        if (isNaN(adds[i].lng) || isNaN(adds[i].lat)) {
          // eslint-disable-next-line no-throw-literal
          throw "adds[i].lng or adds[i].lat is not a number!";
        }

        // eslint-disable-next-line no-undef
        let point = new BMap.Point(adds[i].lng, adds[i].lat);
        await Geo.getLocation(point, rs => {
          var addComp = rs.addressComponents;
          addressList.push(
            addComp.province +
              " " +
              addComp.city +
              " " +
              addComp.district +
              " " +
              addComp.street +
              " " +
              addComp.streetNumber
          );
          if (i < arr.length - 1) {
            i++;
            return searchGeo();
          } else {
            callBack(addressList);
          }
        });
      }
      return searchGeo();
    },
    infoWindowClose(marker) {
      marker.showFlag = false;
    },
    infoWindowOpen(marker) {
      marker.showFlag = true;
    },
    getClickInfo(e) {}
  },
  components: {
    Header
    // infoWindowComponent
  }
};
</script>

<style lang="scss" scoped>
.Map {
  width: 100%;
  height: calc(930 / 1080 * 100vh);
  margin-top: calc(15 / 1080 * 100vh);
  position: relative;
  .search {
    position: relative;
    position: absolute;
    top: calc(20 / 1920 * 100vw);
    left: calc(20 / 1920 * 100vw);
    width: calc(300 / 1920 * 100vw);
    height: calc(38 / 1080 * 100vh);
    border-radius: 4px;
    box-sizing: border-box;
    ::v-deep .el-input__inner {
      background: #f0f2fa;
      height: calc(40 / 1080 * 100vh);
    }
    .el-button {
      right: 0;
      position: absolute;
      width: 76px;
      border-radius: 0 4px 4px 0 !important;
    }
  }
  .FacilityBack {
    position: absolute;
    bottom: calc(20 / 1920 * 100vw);
    left: calc(20 / 1920 * 100vw);
    width: calc(250 / 1920 * 100vw);
    height: calc(150 / 1080 * 100vh);
    opacity: 0.8;
    border: calc(2 / 1920 * 100vw) solid #0196ff;
    box-sizing: border-box;
    ul {
      color: #434343;
      li {
        height: calc(50 / 1080 * 100vh);
        display: flex;
        align-items: center;
        position: relative;
        .text {
          margin-right: calc(2 / 1920 * 100vw);
        }
        .round {
          margin-left: calc(70 / 1920 * 100vw);
        }
        .round::after {
          content: "";
          width: calc(20 / 1920 * 100vw);
          height: calc(20 / 1920 * 100vw);
          border-radius: 50%;
          background-color: #2dc1b8;
          position: absolute;
          top: calc(14 / 1080 * 100vh);
          left: calc(38 / 1920 * 100vw);
        }
        .text {
          margin-left: calc(10 / 1920 * 100vw);
          text-align: right;
          width: calc(40 / 1920 * 100vw);
        }
        .rounds {
          margin-left: calc(70 / 1920 * 100vw);
        }
        .rounds::after {
          content: "";
          width: calc(20 / 1920 * 100vw);
          height: calc(20 / 1920 * 100vw);
          border-radius: 50%;
          background-color: #ff7200;
          position: absolute;
          top: calc(14 / 1080 * 100vh);
          left: calc(38 / 1920 * 100vw);
        }
        .round-gray {
          margin-left: calc(70 / 1920 * 100vw);
        }
        .round-gray::after {
          content: "";
          width: calc(20 / 1920 * 100vw);
          height: calc(20 / 1920 * 100vw);
          border-radius: 50%;
          background-color: #999999;
          position: absolute;
          top: calc(14 / 1080 * 100vh);
          left: calc(38 / 1920 * 100vw);
        }
      }
    }
  }
  .map {
    width: 100%;
    height: 100%;
  }
}
::v-deep .Back_box {
  margin-top: calc(50 / 1080 * 100vh);
  width: calc(460 / 1920 * 100vw);
  height: calc(140 / 1080 * 100vh);
  background: #ffffff;
  border: calc(4 / 1920 * 100vw) solid #0e7771;
  border-radius: 8px;
  color: #333;
  .texts {
    width: calc(180 / 1920 * 100vw);
    font-size: calc(20 / 1920 * 100vw);
  }
  ul {
    margin-top: calc(12 / 1080 * 100vh);
    li {
      height: calc(40 / 1080 * 100vh);
      // margin-top: calc(10 / 1080 * 100vh);
      margin-left: calc(30 / 1920 * 100vw);
      display: flex;
      .gray {
        color: #666;
      }
      span {
        display: flex;
        align-items: center;
      }
      .blue {
        color: #3b87ea;
        img {
          width: calc(17 / 1920 * 100vw);
          height: calc(19 / 1080 * 100vh);
          margin-left: calc(150 / 1920 * 100vw);
          margin-right: calc(6 / 1920 * 100vw);
        }
      }
    }
  }
}
::v-deep .test {
  width: calc(164 / 1920 * 100vw);
  height: calc(33 / 1080 * 100vh);
  background: #ffffff;
  color: #333;
  z-index: 1;
}
#containers {
  height: 100%;
  width: 100%;
}
</style>
